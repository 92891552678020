import React from 'react'
import {
  Breadcrumbs,
  Hero,
  Optimization,
  Page,
  ServiceContact,
} from '@components'
import { buildLocalizedPath } from '@utils/functions'

const meta = {
  title: 'Optimizacija troškova protupožarne zaštite | FSB d.o.o.',
  description: 'Projekte analiziramo s multidisciplinarnim timom i poznavanjem široke paleta mogućih tehničkih rješenja, uz suglasnost svih sudionika u gradnji, odabiremo optimalno cjenovno rješenje.',
  image: require('@assets/images/opti.jpg'),
}

const Homepage = ({ pageContext }) => (
  <Page
    isHomepage
    meta={meta}
    locale={pageContext.locale}
  >
    <Breadcrumbs
      links={[
        { link: buildLocalizedPath('/', pageContext.locale), name: 'header.link.home'},
        { link: buildLocalizedPath('/services/', pageContext.locale), name: 'header.link.services'},
        { link: buildLocalizedPath('/services/cost-optimization-of-fire-protection/', pageContext.locale), name: 'service.2.hero.subtitle'},
      ]}
    />
    <Hero
      title="service.2.hero.title"
      subtitle="service.2.hero.subtitle"
      description="service.2.hero.description"
    />
    <Optimization locale={pageContext.locale} />
    <ServiceContact id="optimizacija" locale={pageContext.locale} />
  </Page>
)

export default Homepage